export const EXPERIMENTS = {
  DYNAMIC_TAGLINE: 'specs.membership.PricingPlansTpaDynamicTagline',
  ENABLE_COUPONS: 'specs.membership.EnableCoupons',
  FIT_TO_CONTENT_HEIGHT: 'specs.membership.FitToContentHeight',
  SHOW_ADD_COUPON_LABEL: 'specs.membership.ShowAddCouponLabel',
  PLAN_LIMIT: 'specs.membership.PlanLimit',
  SHOW_ERRORS_IN_CASHIER_WIDGET: 'specs.membership.ShowErrorsInCashierWidget',
  USE_BLOCKS_SINGLE_PLAN_WIDGET: 'specs.membership.UseBlocksSinglePlanWidget',
  USE_BLOCKS_PACKAGE_PICKER_PAGE: 'specs.membership.UseBlocksPackagePickerPage',
};
